<template>
  <div class="main">
    <Navigation :spreadProduct="'#359efe'" />
    <img class="report_content  pc_show mobel_show" src="@/assets/img/product/pet/banner_pc.svg" />
    <div class="content">
      <!-- 设计理念 -->
      <!-- <div class="same DesignConcept">
        <div class="conceptTop">
          <div class="leftText">
            <img class="DesignBg" :src="loveSeriesImg" alt="" />
            <div class="Design">
              <div class="Title-C nameC">设计理念</div>
              <div class="Title-E nameE">DESIGN CONCEPT</div>
              <div class="line"></div>
              <div class="DetailText detail">
                <p class="pcShow loveStart">“基因检测性格新选择 ”</p>
                <p class="pcShow">
                  纷繁复杂的生物圈 有一条条无形的碳链把我们相连
                </p>
                <p class="mobileShow">纷繁复杂的生物圈</p>
                <p class="mobileShow">有一条条无形的碳链把我们相连</p>
                <p>设计师将浪漫的DNA 动人的碳链</p>
                <p>璀璨夺目的水晶以实物的形式唯美定格</p>
                <p>把两个人的DNA放在一起,</p>
                <p>让载体伴随着生命的能量绽放,</p>
                <p>一切都会是爱的模样</p>
                <p>从细胞到奇点情感终将幻化成无限循环的双螺旋</p>
              </div>
            </div>
          </div>
          <div class="rightImg mobileImg">
            <img :src="carbonChainImg" alt="" />
          </div>
        </div>
      </div> -->


      <!-- 设计理念 PC -->
      <img class="report_content pc_show" src="@/assets/img/product/pet/design1_pc.svg" />
      <!-- 设计理念 Mobel -->
      <img class="report_content mobel_show" src="@/assets/img/product/pet/design1_mobel.svg" />

      <!-- 4个desc PC -->
      <img class="report_content pc_show" src="@/assets/img/product/pet/desc1_pc.svg" />
      <!-- 4个desc Mobel -->
      <img class="report_content mobel_show" src="@/assets/img/product/pet/desc1_mobel.svg" />

      <!-- 报告内容 PC-->
      <img class="report_content pc_show" src="@/assets/img/product/pet/img_dog.svg" />
      <!-- 报告内容 Mobel -->
      <img class="report_content mobel_show" src="@/assets/img/product/pet/img_dog_mobel.svg" />
      <!-- 2 -->
      <img class="report_content  pc_show mobel_show" src="@/assets/img/product/pet/show1.svg" />
      <!-- 3  PC-->
      <img class="report_content pc_show" src="@/assets/img/product/pet/show2_pc.svg" />
      <!-- 3 Mobel-->
      <img class="report_content mobel_show" src="@/assets/img/product/pet/show2_mobel.svg" />
    </div>
  </div>
</template>

<script>
import Navigation from "../../components/Navigation.vue";

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      bannerImg: require("@/assets/img/product/pet/banner.jpg"), // 爱情系列大图
      loveSeriesImg: require("@/assets/img/product/loveSeries.png"),
      carbonChainImg: require("@/assets/img/product/pet/concept.jpg"), // 基因碳链图片
      bruin: require("@/assets/img/product/pet/bruin.png"), // 抱抱熊图片
    };
  },
  mounted() {
    // 移动端图片
    if (window.screen.width >= 320 && window.screen.width <= 1023) {
      this.loveSeriesImg = require("@/assets/img/h5/product/loveSeries.jpg");
      // 产品展示
      this.honeyPot = require("@/assets/img/h5/product/love/honeyPot.png"); // 蜜罐图片
      this.menNecklace = require("@/assets/img/h5/product/love/menNecklace.png"); // 男士项链图片
      this.missNecklace = require("@/assets/img/h5/product/love/missNecklace.png"); // 女士项链图片
      this.bruin = require("@/assets/img/h5/product/love/bruin.png"); // 抱抱熊图片
      // 包装展示
      this.lanternBox = require("@/assets/img/h5/product/love/lanternBox.png");
      this.bruinBox = require("@/assets/img/h5/product/love/bruinBox.png");
      this.giftBox = require("@/assets/img/h5/product/love/giftBox.png");
    }
  },
};
</script>

<style lang="less">
.main {
  width: 100%;
  background-color: #fff;


// Mobel
  @media (min-width: 320px) and (max-width: 1023px) {
    .mobel_show{
    display: block;
}
    // 主要内容
    .content {
      position: relative;
      // 中文小标题
      .Title-C {
        font-size: 0.5rem;
        font-weight: bold;
        color: #333333;
      }
      // 英文小标题
      .Title-E {
        opacity: 0.5;
        color: #000000;
        font-size: 0.34rem;
        font-weight: 500;
        padding: 0.2rem 0 0.15rem 0;
      }
      // 详细描述的文字
      .DetailText {
        text-align: left;
        font-size: 0.285rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.59rem;
        padding: 0.23rem 0;
      }
      // 敬请期待
      .expect {
        text-align: center;
        padding-top: 0.52rem;
        font-size: 0.34rem;
        font-weight: 500;
        opacity: 0.5;
      }

      // 分隔线
      .separate {
        height: 0.2rem;
        background-color: #f3f2f2;
      }
      .pcShow {
        display: none;
      }

      .productNameC,
      .productNameE {
        text-align: center;
      }

      // 设计理念
      .DesignConcept {
        position: relative;
        padding-top: 0.6rem;
        // .conceptTop {
        //   display: flex;
        //   flex-direction: column;
        //   .leftText {
        //     .DesignBg {
        //       position: absolute;
        //       z-index: 50;
        //       left: 1.63rem;
        //       height: 1.3rem;
        //     }
        //     .Design {
        //       position: relative;
        //       z-index: 100;
        //       padding: 1rem 0.3rem 0.3rem;
        //       text-align: center;
        //       .nameE {
        //         padding-bottom: 0.31rem;
        //       }
        //       .line {
        //         float: right;
        //         width: 29%;
        //         height: 2px;
        //         background-color: #efefef;
        //       }
        //       .detail {
        //         text-align: center;
        //       }
        //     }
        //   }
        //   .mobileImg {
        //     img {
        //       width: 7.5rem;
        //       height: 6.45rem;
        //     }
        //   }
        // }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 374px) {
    .content {
   
    }
  }
// PC
  @media (min-width: 1024px) {
    // 主要内容
    .content {
      margin-top: 0.13rem;
      position: relative;
      .same {
        width: 80%;
        margin: 0 auto;
      }
      .sames {
        width: 85%;
        margin: 0 auto;
      }

      // 系列标题
      .Title {
        font-size: 0.375rem;
        font-weight: bold;
        margin-left: -0.07rem;
      }
      // 中文小标题
      .Title-C {
        font-size: 0.2604rem;
        font-weight: bold;
      }
      // 英文小标题
      .Title-E {
        opacity: 0.5;
        font-size: 0.1354rem;
        font-weight: 500;
        padding: 0.14rem 0 0.15rem 0.02rem;
      }
      // 详细描述的文字
      .DetailText {
        text-align: left;
        font-size: 0.1145rem;
        font-weight: 500;
        color: #222222;
        line-height: 0.195rem;
      }

      .mobileShow {
        display: none;
      }

      // 设计理念
      .DesignConcept {
        position: relative;
        padding-bottom: 0.2rem;
        // 上部分
        // .conceptTop {
        //   .leftText {
        //     display: inline-block;
        //     .DesignBg {
        //       width: 6.979rem;
        //       height: 0.8125rem;
        //       margin: 0.84rem 0 0 0.05rem;
        //     }
        //     .Design {
        //       padding-left: 0.84rem;
        //       position: relative;
        //       .nameC {
        //         color: #333333;
        //         line-height: 0.234rem;
        //         position: absolute;
        //         top: -0.2rem;
        //       }
        //       .nameE {
        //         color: #000000;
        //       }
        //       .loveStart {
        //         font-weight: bold;
        //         font-size: 0.14rem;
        //         padding-bottom: 0.06rem;
        //       }
        //     }
        //   }
        //   .rightImg {
        //     display: inline-block;
        //     position: absolute;
        //     top: 0.25rem;
        //     right: 0;
        //     img {
        //       width: 3.354rem;
        //       height: 3.354rem;
        //     }
        //   }
        // }
      }

      //   四个描述
    }
  }
  // and (max-width: 1680px)
  @media (min-width: 1024px)  {
    .pc_show{
        display: block;
    }
    .content {
      .Title-C {
        font-size: 0.2rem;
      }
    
     
    }
  }

  @media (min-width: 1920px) {
    .desc1_4 {
      width: 100%;
    }
    .content {
      .Title-C {
        font-size: 0.2rem;
      }
    }
  }
}
.report_content {
  width: 100%;
  display: none;
}
</style>
